import React, {useEffect} from 'react';

const RedirectPage = props => {
    console.log({props})
    useEffect(() => {
        window.location.href = props.pathContext.redirect
    }, [])
    return <div></div>
};

export default RedirectPage